import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce';
import { useGetClientsQuery } from '../../../utils/services/api/clientApi';
import Repertories from './repertories';
import AddCustomer from './AddCustomer';
import { useSelector } from 'react-redux';
import Badge from '../../../components/tabler/Badge';
import { items } from '../../../redux/selectors';

function Header({data, filter, setFilter, client, setClient, nomStore}) {

    const handleSearch = useDebouncedCallback((e) =>{
        e.preventDefault();
        setFilter({...filter, query: e.target.value})
      }, 100);

      // Ceci permet de filter le tableau de categories pour eliminer les doublons
      const categoriesDoublons = data?.reduce((acc, item) => {
        // Vérifier si un élément avec le même nom de catégorie existe déjà dans 'acc'
        if (!acc.some((category) => category.nomCategorie === item.nomCategorie)) {
            // Ajouter l'élément à 'acc' s'il n'existe pas déjà
            acc.push({ nomCategorie: item.nomCategorie, imageUrl: item.imageCategorie });
        }
        return acc;
    }, []);

    // Étape 2: Utiliser filter pour vérifier les doublons de nom de catégorie
    const categories = categoriesDoublons?.filter((item, index, self) => {
        // Retourner true seulement si l'index actuel est le premier index de cet élément dans 'self'
        return index === self.findIndex((t) => (
            t.nomCategorie === item.nomCategorie
        ));
    });

    const {data: clients, isLoading} = useGetClientsQuery();

    // On utilise cette partie pour initialiser le state du premier client
    useEffect(()=>{
        if(!isLoading){
            if(clients?.length>0){
                setClient(clients[clients?.length-1]);
            }
        }
    },[isLoading])

    const handleSelectClient =(e)=> {
        const idSelect = parseInt(e.target.value);
        const selectItem = clients?.find(item=> item.id === idSelect);
        setClient(selectItem)
    }

    const [icon, setIcon] = useState('');
    useEffect(()=>{
        for(let i of categories){
            if(i.nomCategorie===filter.nomCategorie){
                setIcon(i.imageUrl)
            }else{
                setIcon('')
            }
        }
    }, [filter])

    const item = useSelector(items);

  return (
    <div className="page-header d-print-none">
        <div className="container-xl">
            <div className="row g-2 align-items-center">
                <div class="col">
                    <h2 class="page-title">
                        Point de vente
                    </h2>
                    <div class="text-muted mt-1">{nomStore}: {data.length} Articles</div>
                </div>
                <div className="col-auto ms-auto d-print-none">
                    <div className="d-flex align-items-center justify-content-center flex-wrap gap-2">
                        <div className="me-2">
                            <div style={{display: 'flex', alignItems: 'center',
                                justifyContent: 'space-between', height: '42px', width: '190px',
                                border: '1px solid #999', borderRadius: '5px', background: '#fff'
                                }}
                            >
                                <select style={{border: 'none', outline: 'none', fontSize: '13px', fontFamily: 'Poppins'}}
                                    value={client.id}
                                    onChange={handleSelectClient}>
                                {clients?.map((item, i)=> (
                                    <option value={item.id} key={i}>
                                        {item.nom} {item.prenom}
                                    </option>
                                ))}
                                </select>
                                <button data-bs-toggle="modal" data-bs-target="#modal-AddCustomer" className="btn btn-primary"
                                    style={{border: 'none', height: '100%', width: '35px', borderRadius: '0px 5px 5px 0px', color: '#fff'}}>
                                    +
                                </button>
                            </div>
                        </div>
                        <div className="me-2">
                            <div 
                                style={{display: 'flex', alignItems: 'center',
                                justifyContent: 'center', height: '42px', minWidth: '140px',
                                border: '1px solid #999', borderRadius: '5px', background: '#fff'
                                }}>
                                {icon!== '' &&
                                <img src={icon} alt='icon'
                                    style={{maxWidth: '25px', margin: '5px 0px 5px 5px'}}
                                />}
                                <select value={filter.nomCategorie} style={{border: 'none', outline: 'none', fontSize: '13px', fontFamily: 'Poppins'}}
                                    onChange={(e)=> setFilter({...filter, nomCategorie: e.target.value})}>
                                    <option value=''>Categories</option>
                                {categories?.map((item, i)=> (
                                    <option value={item.nomCategorie} key={i}>
                                        {item.nomCategorie}
                                    </option>
                                ))}
                                </select>
                            </div>

                        </div>
                        <div className="me-2">
                            <div className="input-icon">
                                <input type="text" className="form-control" 
                                    onChange={handleSearch}
                                    placeholder="Entrez nom produit, SKU, Scanner code Barres"/>
                                <span className="input-icon-addon">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M21 21l-6 -6" /></svg>
                                </span>
                            </div>
                        </div>

                        <div className='d-flex'>
                            <div className='d-block d-lg-none'>
                                    <button className="btn btn-icon btn-primary me-2 position-relative" data-bs-toggle="offcanvas" href="#offcanvasEnd" aria-controls="offcanvasEnd">
                                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-shopping-cart"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 17h-11v-14h-2" /><path d="M6 5l14 1l-1 7h-13" /></svg>
                                        <Badge color='error'>{item}</Badge>
                                    </button>
                            </div>
                            <button className="btn btn-icon btn-info" data-bs-toggle="modal" data-bs-target="#modal-historique">
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="currentColor"  className="icon icon-tabler icons-tabler-filled icon-tabler-clock-hour-8"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 3.34a10 10 0 1 1 -15 8.66l.005 -.324a10 10 0 0 1 14.995 -8.336m-5 2.66a1 1 0 0 0 -1 1v4.464l-2.555 1.704a1 1 0 0 0 -.336 1.286l.059 .1a1 1 0 0 0 1.387 .278l3.027 -2.018l.087 -.07l.074 -.075l.075 -.094l.052 -.08l.035 -.07l.051 -.132l.031 -.135l.01 -.082l.003 -.076v-5a1 1 0 0 0 -1 -1" /></svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <AddCustomer/>
        <Repertories/>
    </div>
  )
}

export default Header