import dayjs from 'dayjs';

export const generatePagination = (currentPage, totalPages) => {
    if (totalPages <= 7) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
  
    if (currentPage <= 3) {
      return [1, 2, 3, '...', totalPages - 1, totalPages];
    }
  
    if (currentPage >= totalPages - 2) {
      return [1, 2, '...', totalPages - 2, totalPages - 1, totalPages];
    }
  
    return [
      1,
      '...',
      currentPage - 1,
      currentPage,
      currentPage + 1,
      '...',
      totalPages,
    ];
  };

export const randomNumberInRange = (min, max) =>{
  return Math.floor(Math.random()
        * (max - min + 1)) + min;
}

export const timing = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  // Calculer la difference en jours
  const diffDays = end.diff(start, 'day');

  // Convertir la difference en mois et années 
  const months = Math.floor(diffDays/30);
  const years = Math.floor(diffDays/365);
  const days = diffDays % 365;

  // Retourner le format approprié
  if(years>0){
    const remainingMonths = Math.floor((days % 365)/30);
    return `${years} ans(s) ${remainingMonths>0 ? `${remainingMonths} mois` : ""}`;
  } else if(months > 0){
    return `${months} mois ${diffDays % 30 > 0 ? `${diffDays % 30} jour(s)` : ""}`;
  } else {
    return `${diffDays} jour(s)`;
  }
}

// Pour limiter le nombre de caractère d'une balise
export const LimiterText = (texte, maxLength) => {
  const texteLimite = texte.length > maxLength ? (texte.slice(0, maxLength) + '...') : texte;
  return texteLimite;
}