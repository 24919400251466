import dayjs from 'dayjs';
import Status from '../../../../components/tabler/Status';
import { useGetOneProductQuery } from '../../../../utils/services/api/productApi'
import empty from '../../../../assets/images/empty.jpg';

function ViewArticle({id}) {
    
    const {data: article} = useGetOneProductQuery(id);
    
  return (
    <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasEnd" aria-labelledby="offcanvasEndLabel">
        <div className="offcanvas-header">
            <h2 className="offcanvas-title" id="offcanvasEndLabel">Article {article?.id}</h2>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <div>
                <div className="card">
                    <div className="card-header">
                    <h3 className="card-title">Details: {article?.SKU}</h3>
                    </div>
                    <div className="list-group list-group-flush list-group-hoverable">
                    <div className="list-group-item">
                        <div className="row align-items-center">
                        <div className="col-auto">
                            <span className="avatar" style={{backgroundImage: `url(${article?.imageUrl ? article?.imageUrl : empty})`}}></span>
                        </div>
                        <div className="col text-truncate">
                            <div className="text-reset d-block">Nom Article</div>
                            <div className="d-block text-muted text-truncate mt-n1">{article?.nomArticle}</div>
                        </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                        <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                            <div className="text-reset ">Categorie</div>
                            <div className=" text-muted text-truncate mt-n1">{article?.category.nomCategorie}</div>
                        </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Type Produit</div>
                                <div className="text-muted text-truncate mt-n1">{article?.typeProduit}</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Prix de vente</div>
                                <div className="text-muted text-truncate mt-n1">{article?.prixVente}</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                        <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                            <div className="text-reset">Magasin</div>
                            <div className="text-muted text-truncate mt-n1">{article?.magasin.nomStore}</div>
                        </div>
                        </div>
                    </div>
                    <div className="list-group-item">
                        <div className="row align-items-center">
                        <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                            <div className="text-reset">Statut</div>
                            <div className="text-muted text-truncate mt-n1">
                                <Status color={article?.suiviStock ? ((article?.statut && article?.enStock > 0) ? 'green' : 'red') : (article?.statut ? 'green' : 'red')}>
                                    {article?.suiviStock ? 
                                    (article?.statut && article?.enStock > 0 ? 'En vente' : (!article?.statut && article?.enStock > 0 ? 'Brouillon' : 'Rupture')) 
                                    : article?.statut ? 'En vente' : 'Brouillon'}
                              </Status>
                            {/* <Status color={article?.statut ? 'green' : 'red'}>{article?.statut ? 'En vente' : 'Brouillon'}</Status> */}
                            </div>
                        </div>
                        </div>
                    </div>
                    {article?.suiviStock &&
                        <div className="list-group-item">
                            <div className="row align-items-center">
                                <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                    <div className="text-reset">Stock</div>
                                    <div className="text-muted text-truncate mt-n1">{article?.enStock}</div>
                                </div>
                            </div>
                        </div>
                    }
                    {article?.suiviStock &&
                        <div className="list-group-item">
                            <div className="row align-items-center">
                                <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                    <div className="text-reset">Quantité Alerte</div>
                                    <div className="text-muted text-truncate mt-n1">{article?.stockFaible}</div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="list-group-item">
                        <div className="row align-items-center">
                            <div className="text-truncate" style={{display: 'flex', flexDirection: 'row', gap: 25 }}>
                                <div className="text-reset">Date d'enregistrement</div>
                                <div className="text-muted text-truncate mt-n1">{dayjs(article?.createdAt).format('DD-MM-YYYY')}</div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ViewArticle