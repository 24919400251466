import React from 'react'
import icon from '../../assets/images/BlueMixteLogo.png'
import { useGetOneMagasinQuery } from '../../utils/services/api/magasinsApi';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const InvoiceItem = React.forwardRef((props, ref) => {

    dayjs.extend(utc);
    dayjs.extend(timezone);

    const {data: magasin, isLoading} = useGetOneMagasinQuery(props?.idStore);

    // const options = { timeZone: "America/Port-au-Prince", year: "numeric",
    //     month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit",
    //     second: "2-digit"
    //   };
    
    if(isLoading){
        return(
            <div className="card" ref={ref}>
    
            </div>
        )
    }

  return (
    <div className="card" ref={ref}>
        <div className="card-body" style={{fontFamily: 'Poppins'}}>
            <div className="row m-2">
                <div className="col-12 mb-2 text-center">
                    <img src={icon} height="60" alt=""/>
                </div>
                <div className="col-12 text-center">
                    <div style={{fontSize: '12px'}}>
                        <span className='strong'>{magasin?.nomStore}</span><br/>
                        {`${magasin?.repere}, ${magasin?.ville}`}<br/>
                        {`${magasin?.etat}, ${magasin?.pays}`}<br/>
                        Email: {magasin?.email}<br/>
                        Tel: {magasin?.tel}<br/>
                        Client: <span className='strong'>{props.customer}</span><br/>
                        Vendu par: <span className='strong'>{props.salesPerson}</span><br/>
                        Date: {dayjs(props.date).tz("America/Port-au-Prince").format('DD/MM/YYYY hh:mm')}
                        {/* Date: {Intl.DateTimeFormat("fr-CA", options).format(new Date(props.date))} */}
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <div className='h4'>Facture: {props.noFacture}</div>
                </div>
            </div>
            <div className='table-responsive d-flex align-items-center m-1'>
                <table className="table table-transparent" style={{width: '100%', fontSize: '12px', marginLeft: '13px', marginRight: '13px'}} >
                <thead>
                    <tr>
                        <th>Product</th>
                        <th className="text-center">Qnt</th>
                        <th className="text-end">Unit</th>
                        <th className="text-end">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {props.list?.map((item, i)=> (
                        <tr key={i}>
                            <td>{item.nom}</td>
                            <td className="text-center">{item.quantity}x</td>
                            <td className="text-end">${item.prixVente}</td>
                            <td className="text-end">${item.prixVente*item.quantity}</td>
                        </tr>
                    ))
                    }

                    <tr>
                        <td colspan="3" className="strong text-end">Items</td>
                        <td className="text-end">{props.item}x</td>
                    </tr>
                    {props.taxes!==0 &&
                    <tr>
                        <td colspan="3" className="strong text-end">Taxes</td>
                        <td className="text-end">${props.taxes}</td>
                    </tr>}
                    {props.discount!==0 &&
                    <tr>
                        <td colspan="3" className="strong text-end">Discount</td>
                        <td className="text-end">${props.discount}</td>
                    </tr>}

                    {props.montantVerser!==0 && props.monnaie!==0 &&
                    <tr>
                        <td colspan="3" className="strong text-end">Montant verser</td>
                        <td className="text-end">${props.montantVerser}</td>
                    </tr>}
                    {props.montantDue!==0 &&
                    <tr>
                        <td colspan="3" className="strong text-end">Balance</td>
                        <td className="text-end">${props.montantDue}</td>
                    </tr>}
                    {props.renduMonnaie!==0 &&
                    <tr>
                        <td colspan="3" className="strong text-end">Monnaie</td>
                        <td className="text-end">${props.renduMonnaie}</td>
                    </tr>}
                    <tr>
                        <td colspan="3" className="strong text-end">Subtotal</td>
                        <td className="text-end">${props.totalSale}</td>
                    </tr>
                    <tr>
                        <td colspan="3" className="font-weight-bold text-uppercase text-end">Total</td>
                        <td className="font-weight-bold text-end">
                            ${(parseFloat(props.totalSale)+parseFloat(props.taxes)) - parseFloat(props.discount)}
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
            <p className="text-muted text-center mt-5 m-2 mb-5" style={{fontSize: '13px'}} >
                {/* Thank you very much for doing business with us. We look forward to working with
                you again! */}
                Merci de nous avoir visité, À bientôt!
            </p>
        </div>
    </div>
  )
});