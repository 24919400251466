import AuthLayout from "../layout/authLayout";
import ForgotPassword from "../pages/auth/forgot-password/Forgot-password";
import Login from "../pages/auth/login/Login";
import Error from "../pages/errors/not-found/error-404";

export const AuthRoutes = {
    path: '/',
    element: <AuthLayout/>,
    errorElement: <Error/>,
    children: [
        {
            path: '/login',
            element: <Login/>
        },{
            path: '/forgot',
            element: <ForgotPassword/>
        }
    ]
}