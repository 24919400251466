import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { user } from '../../../../redux/selectors'
import { authSlice } from '../../../../redux/slices/authSlice';
import empty from '../../../../assets/images/empty.jpg';

function Profil() {
  const currentUser = useSelector(user);
  const dispatch = useDispatch();

  return (
    <div >
        <NavLink to="#1" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
            <span className="avatar avatar-sm" style={{backgroundImage: `url(${currentUser.imageUrl ? currentUser.imageUrl : empty})`}}></span>
            <div className="d-none d-xl-block ps-2">
            <div>{currentUser.username}</div>
            <div className="mt-1 small text-muted">{currentUser.role}</div>
            </div>
        </NavLink>
        <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            {/* <NavLink to="#2" className="dropdown-item">Status</NavLink> */}
            {/* <NavLink to="./profile.html" className="dropdown-item">Profile</NavLink> */}
            {/* <NavLink to="#3" className="dropdown-item">Feedback</NavLink> */}
            {/* <div className="dropdown-divider"></div> */}
            {/* <NavLink to="/reglages" className="dropdown-item">Settings</NavLink> */}
            <button onClick={()=> dispatch(authSlice.actions.logout())} className="dropdown-item">Logout</button>
        </div>
    </div>
  )
}

export default Profil