import React, { useContext } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import cover from '../../../assets/images/bg.jpg';
import logo from "../../../assets/images/BlueLogo.png";
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { credentialSchema } from '../../../utils/schemas/credentialSchema';
import { useCreateConnectionMutation } from '../../../utils/services/api/userApi';
import { useDispatch } from 'react-redux';
import { authSlice } from '../../../redux/slices/authSlice';
import { ToastContext } from '../../../utils/contexts/context';

function Login() {

  const navigate = useNavigate();

  const [toast, setToast] = useContext(ToastContext);

  const dispatch = useDispatch();
  
  const [createConnection] = useCreateConnectionMutation();

  const {register, handleSubmit, formState: { errors }} = useForm({
    resolver: zodResolver(credentialSchema)
  })

  const onSubmit = (data) => {
   
    createConnection(data)
    .then(resp => {
      if(resp.data){
        if(resp.data.token){
          setToast({
            ...toast,
            isOpen: true,
            severity: 'success',
            message: "Connexion réussie"
          })

          dispatch(authSlice.actions.setCredential({
            user: resp.data.user,
            token: resp.data.token,
            privileges: resp.data.privileges,
            magasins: resp.data.magasins
          }))

          navigate('/dashboard')
        }else{
          setToast({
            ...toast,
            isOpen: true,
            severity: 'danger',
            message: resp.data.message
          })
        }
      }
    }).catch((error)=>{
      setToast({
        ...toast,
        isOpen: true,
        severity: 'danger',
        message: error.response.data.message
      })
    })
  }

  return (
    <div className=" d-flex flex-column bg-white">
      <div className="row g-0 flex-fill">
        <div className="col-12 col-lg-6 col-xl-4 border-top-wide border-primary d-flex flex-column justify-content-center">
          <div className="container container-tight my-5 px-lg-5">
            <div className="text-center mb-4">
              <NavLink to="." className="navbar-brand navbar-brand-autodark"><img src={logo} height="80" alt=""/></NavLink>
            </div>
            <h2 className="h3 text-center mb-3">
              Connectez-vous à votre compte
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input type="email" className={`form-control ${errors.email && 'is-invalid'}`} placeholder="email" {...register('email')}/>
                {errors.email && <div class="invalid-feedback">{errors.email.message}</div>}
              </div>
              <div className="mb-2">
                <label className="form-label">
                    Mot de passe
                  <span className="form-label-description">
                    <NavLink to="/forgot">J’ai oublié mon mot de passe</NavLink>
                  </span>
                </label>
                  <input type="password" className={`form-control ${errors.password && 'is-invalid'}`} placeholder="mot de passe" {...register('password')}/>
                  {errors.password && <div class="invalid-feedback">{errors.password.message}</div>}
              </div>
              <div className="mb-2">
                <label className="form-check">
                  <input type="checkbox" className="form-check-input"/>
                  <span className="form-check-label">Se souvenir de moi sur cet appareil</span>
                </label>
              </div>
              <div className="form-footer">
                <button type="submit" className="btn btn-primary w-100">Connexion</button>
              </div>
            </form>
            {/* <div className="text-center text-muted mt-3">
              Vous n’avez pas encore de compte ? <NavLink to="./sign-up.html" tabindex="-1">Sign up</NavLink>
            </div> */}
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-8 d-none d-lg-block">
          <div className="bg-cover h-100 min-vh-100" style={{backgroundImage: `url(${cover})`}}></div>
        </div>
    </div>
    </div>
  )
}

export default Login