import React, { useEffect, useState } from 'react'
import Header from '../../../components/header-page/Header'
import Status from '../../../components/tabler/Status'
import { useGetProductsQuery } from '../../../utils/services/api/productApi'
import { generatePagination } from '../../../utils';
import { useDebouncedCallback } from 'use-debounce';
import FilterArticle from './components/FilterArticle';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import DeleteArticle from './mutation/DeleteArticle';
import BarcodeReader from 'react-barcode-reader';
import ViewArticle from './query/ViewArticle';
import empty from '../../../assets/images/empty.jpg';

function Articles() {

  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    query : '',
    nomCategorie: '',
    nomStore : '',
    statut: ''
  });

  useEffect(()=> {
    setCurrentPage(1)
  }, [filter])

  const handleSearch = useDebouncedCallback((e) =>{
    e.preventDefault();
    setFilter({...filter, query: e.target.value})
  }, 100);

  
  const { data: products, isLoading} = useGetProductsQuery(filter);

// Pour exporter en format Excel
  const handleExport = ()=>{
    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(products);

    XLSX.utils.book_append_sheet(wb,ws, "MySheet1");
    XLSX.writeFile(wb, 'Liste_produits.xlsx');
}

   // Pour la pagination
   const [currentPage, setCurrentPage] = useState(1);
   const [recordsPerPage, setRecordsPerPage] = useState(10);
   const lastIndex = currentPage * recordsPerPage;
   const firstIndex = lastIndex - recordsPerPage;
   const records = products?.slice(firstIndex, lastIndex);
   const npage = Math.ceil(products?.length/recordsPerPage);
   const numbers = generatePagination(currentPage, npage);

  //  Pour les lignes selectionnées
  const [selected, setSelected] = useState([]);

  // Mutation
  const handleAdd = () => {
    navigate('/articles/ajouter')
  }
  const handleUpdate =(id)=> {
    navigate(`/articles/edit/${id}`)
  }

  const [idToView, setIdToView] = useState(0);
  const handleView = (id) => {
    setIdToView(id)
  }

  // ******** Barcode ********
  const handleScan = (data) => {
    setFilter({...filter, query: data});
  };

  const handleError = (err) => {
    console.log(err);
  };
  // **** End Barcode *****
   
  return (
    <div className="page-wrapper">
        
        <Header title={"Liste d'articles"}/>

        <BarcodeReader
          onError={handleError}
          onScan={handleScan}
        />
        <div className="page-body">
          <div className="container-xl">
            <div className="row row-cards">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Stock</h3>
                    <div className='ms-auto'>
                      { selected.length > 0 &&
                        <button className='btn btn-icon btn-ghost-danger' data-bs-toggle="modal" data-bs-target="#modal-danger" style={{ marginRight: '5px' }}>
                          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                        </button>
                      }

                      <button className='btn btn-icon' 
                        data-bs-toggle="modal" data-bs-target="#modal-team"
                        style={{ marginRight: '7px' }}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-filter"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z" /></svg>
                      </button>

                      <button 
                        onClick={()=>handleExport()} className='btn btn-icon btn-success' data-bs-toggle="tooltip" data-bs-placement="bottom" title="Exportez en format Excel" style={{ marginRight: '7px' }}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-file-spreadsheet"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M8 11h8v7h-8z" /><path d="M8 15h8" /><path d="M11 11v7" /></svg>
                      </button>
                      <button className="btn btn-primary" onClick={()=> handleAdd()}>Ajouter</button>
                    </div>
                  </div>
                  <div className="card-body border-bottom py-3">
                    <div className="d-flex">
                      <div className="text-muted">
                        Show
                        <div className="mx-2 d-inline-block">
                          <select class="form-select" className="form-control form-control-sm" value={recordsPerPage} onChange={(e)=> setRecordsPerPage(e.target.value)}>
                            <option value={10}>10</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                          </select>
                        </div>
                        entries
                      </div>
                      <div className="ms-auto text-muted">
                        Search:
                        <div className="ms-2 d-inline-block">
                          <input type="text" className="form-control form-control-sm" aria-label="Search invoice"
                            // value={filter.search}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table card-table table-vcenter text-nowrap datatable">
                      <thead>
                        <tr>
                          <th className="w-1"><input className="form-check-input m-0 align-middle" type="checkbox" 
                            checked={selected.length === products?.length && products?.length>0} 
                            onChange={(event) => {
                              setSelected(
                                event.target.checked ? products?.map((row) => row.id) : [],
                              );
                            }}
                            aria-label="Select all invoices"/></th>
                          <th>Produit</th>
                          <th>SKU</th>
                          <th>Variante</th>
                          <th>Statut</th>
                          <th>Stock</th>
                          <th>Prix</th>
                          <th>Type</th>
                          <th>Emplacements</th>
                          <th>Date</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && 
                          <tr>
                            <td colSpan={11} style={{textAlign: 'center'}}>Loading<span class="animated-dots"></span></td>
                          </tr>
                        }

                        {records?.map((product, i)=>(
                          <tr key={i}>
                            <td>
                              <input className="form-check-input m-0 align-middle" 
                                  type="checkbox" aria-label="Select invoice"
                                  checked={selected.includes(product.id)}
                                  onChange={(event) => {
                                    setSelected((ids) =>
                                      event.target.checked
                                        ? ids.concat(product.id)
                                        : ids.filter((itemId) => itemId !== product.id),
                                    );
                                  }}
                              />
                            </td>
                            <td>
                              <div className="d-flex py-1 align-items-center">
                                <span className="avatar me-2" style={{backgroundImage: `url(${product.imageUrl ? product.imageUrl : empty})`}}></span>
                                <div className="flex-fill">
                                  <div className="font-weight-medium">{product.nomArticle}</div>
                                  <div className="text-muted">{product.nomCategorie}</div>
                                </div>
                              </div>
                            </td>
                            <td><span className="text-muted">{product.SKU}</span></td>
                            <td>{product.variante}</td>
                            <td>
                              <Status color={product.suiviStock ? ((product.statut && product.enStock > 0) ? 'green' : 'red') : (product.statut ? 'green' : 'red')}>
                                {product.suiviStock ? 
                                  (product.statut && product.enStock > 0 ? 'En vente' : (!product.statut && product.enStock > 0 ? 'Brouillon' : 'Rupture')) 
                                  : product.statut ? 'En vente' : 'Brouillon'}
                              </Status>
                            </td>
                            <td>{product.enStock}</td>
                            <td>{product.prixVente}</td>
                            <td>{product.typeProduit}</td>
                            <td>{product.nomStore}</td>
                            <td>{dayjs(product.createdAt).format('DD/MM/YYYY')}</td>
                            <td className="text-end">
                              <button className="btn btn-icon btn-ghost-secondary" onClick={()=> handleUpdate(product.id)}>
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-edit"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
                              </button>
                              <button className="btn btn-icon btn-ghost-secondary" onClick={()=> handleView(product.id)} data-bs-toggle="offcanvas" href="#offcanvasEnd" aria-controls="offcanvasEnd">
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-eye"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
                              </button>
                            </td>
                          </tr>
                        ))}

                        {products?.length === 0 &&
                          <tr>
                            <td colSpan={11} style={{textAlign: 'center'}}>Aucune donnée n'a été retournée</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer d-flex align-items-center">
                    <p className="m-0 text-muted">Showing <span>{currentPage}</span> to <span>{npage}</span></p>
                    <ul className="pagination m-0 ms-auto">
                      <li className="page-item">
                        <button onClick={prePage} className={`page-link ${currentPage===1 && 'disabled'}`} tabindex="-1">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
                        </button>
                      </li>
                      {!isLoading && (numbers?.map((page, id)=> (
                        <li className={`page-item ${currentPage===page && 'active'}`} key={id}><button className="page-link" onClick={()=> changeCPage(page)}>{page}</button></li>
                      )))}
                      <li className="page-item">
                        <button onClick={nextPage} className={`page-link ${currentPage===npage && 'disabled'}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ViewArticle id={idToView}/>
        <DeleteArticle ids={selected} setIds={setSelected}/>
        <FilterArticle filter={filter} setFilter={setFilter} data={products}/>
    </div>
  )

  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1)
    }
  }
  function changeCPage(id){
    if(!(id==='...')){
      setCurrentPage(id)
  }
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1)
    }
  }

}

export default Articles