import { NavLink } from "react-router-dom"
import logo from "../../../assets/images/BlueLogo.png";

function ForgotPassword() {
  return (
    <div className="page page-center">
      <div className="container container-tight py-4">
        <div className="text-center mb-4 mt-4">
          <NavLink to="." className="navbar-brand navbar-brand-autodark"><img src={logo} height="70" alt=""/></NavLink>
        </div>
        <form className="card card-md">
          <div className="card-body">
            <h2 className="card-title text-center mb-4">Mot de passe oublié</h2>
            <p className="text-muted mb-4">Entrez votre adresse e-mail et votre mot de passe sera réinitialisé et vous sera envoyé par e-mail.</p>
            <div className="mb-3">
              <label className="form-label">Email address</label>
              <input type="email" className="form-control" placeholder="Enter email"/>
            </div>
            <div className="form-footer">
              <a href="#" className="btn btn-primary w-100">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" /><path d="M3 7l9 6l9 -6" /></svg>
                    Envoyez-moi un nouveau mot de passe
              </a>
            </div>
          </div>
        </form>
        <div className="text-center text-muted mt-3">
            N’y pense plus, <NavLink to="/login">Renvoyez-moi</NavLink> à l’écran de connexion.
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword